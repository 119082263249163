import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router";
import FormPages from "./pages/FormPages";
import ListPages from "./pages/ListPages";

import Header from "./components/Header";
import axios from "axios";
import TicketSuccess from "./pages/TicketSuccess";

const username = process.env.REACT_APP_USERNAME;
const password = process.env.REACT_APP_PASSWORD;
const url = process.env.REACT_APP_URL;

function App() {
  useEffect(() => {
    (async () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      const token = localStorage.getItem("token");

      if (!token || token === "undefined" || !token.length) {
        const res = await axios.post(
          url + "/api/auth/login",
          { username, password },
          { headers: { "Content-Type": "application/json", "Accept": "application/json" } },
        );
        const user = res.data?.data?.user ?? 0;
        const token = res.data?.data?.access_token ?? 0;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
      }
    })();
  }, []);
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<FormPages />} />
        <Route path="/list-activate" element={<ListPages />} />
        <Route path="/ticket-success" element={<TicketSuccess />} />
      </Routes>
    </div>
  );
}

export default App;
