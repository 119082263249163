import useForm from "../hooks/useForm";
import { Input } from "./Input";
import * as atom from "../stores";

const InputGroup = ({ label, type, placeholder, atom, ...props }) => {
  const [state, changeState] = useForm(atom);

  return (
    <div className="input-group d-flex align-items-center gap-4 mb-4">
      <label className="required">{label}</label>
      <Input
        required
        type={type ?? "text"}
        aria-label={placeholder}
        placeholder={placeholder}
        value={state.data}
        onChange={changeState}
        {...props}
      />
    </div>
  );
};

export const InputGroupDouble = () => {
  const [firstName, changeFirstName] = useForm(atom.firstNameAtom);
  const [lastName, changeLastName] = useForm(atom.lastNameAtom);

  return (
    <div className="input-group d-flex align-items-center gap-5 mb-4">
      <label className="required">Full Name</label>
      <Input
        required
        type="text"
        aria-label="First name"
        placeholder="First Name"
        value={firstName.data}
        onChange={changeFirstName}
      />
      <Input
        required
        type="text"
        aria-label="Last name"
        placeholder="Last Name"
        value={lastName.data}
        onChange={changeLastName}
      />
    </div>
  );
};

export const InputGroupTriple = () => {
  const [country, changeCountry] = useForm(atom.countryAtom);
  const [city, changeCity] = useForm(atom.cityAtom);
  const [state, changeState] = useForm(atom.stateAtom);

  return (
    <div className="input-group d-flex align-items-center gap-5 mb-4">
      <label className="required">Full Address</label>
      <Input
        required
        type="text"
        aria-label="country"
        placeholder="Country"
        value={country.data}
        onChange={changeCountry}
      />
      <Input
        required
        type="text"
        aria-label="state or province"
        placeholder="State/Province"
        value={state.data}
        onChange={changeState}
      />
      <Input
        required
        type="text"
        aria-label="city"
        placeholder="City"
        value={city.data}
        onChange={changeCity}
      />
    </div>
  );
};

export default InputGroup;
