import React, { useState, useEffect } from "react";

import database from "../../src/firebase";
import "./ListPages.css";
import * as XLSX from "xlsx";

function ListPages() {
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("halo");
    // const auth = database.auth();
    // auth.signInWithEmailAndPassword("reporting@jelajahin.id","juwZ8UEejJQ5J4Tu").then(user => {console.log('user', user)}).catch(err => {console.log(err)})
    fetchData();
  }, []);

  const fetchData = async () => {
    setData([]);
    const db = database.firestore();

    const response = db.collection("active-booking");
    const data_s = await response.get();
    const temp_data = [];
    console.log(data_s.docs, "halo");
    data_s.docs.forEach((item) => {
      temp_data.push(item.data());
      // setData([...data,item.data()])
    });
    setData(temp_data);
  };

  const convertExcel = () => {
    const filename = new Date().toJSON().slice(0, 10);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "test");

    XLSX.writeFile(wb, filename + ".xlsx");
    console.log("halo");
  };

  return (
    <div className="container-table">
      <div className="container-button">
        <button type="button" className="btn-submit btn-md btn" onClick={convertExcel}>
          Download Data
        </button>
      </div>
      <table style={{ marginTop: 20 }}>
        <tr>
          <th>Refnum</th>
          <th>Booking Code</th>
          <th>Nama</th>
        </tr>
        {data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.refnum}</td>
              <td>{val.code}</td>
              <td>{val.firstname}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default ListPages;
