import { useAtom } from "jotai";
import { Input } from "./Input";
import clsx from "clsx";

export const InputPhoto = ({ description, id, atom }) => {
  const [val, setValue] = useAtom(atom);

  const handleChange = (e) => {
    setValue((prevState) => ({ ...prevState, isError: false }));
    const file = e.target.files[0];
    if (file) {
      if (file?.size > 1024000) {
        setValue((prevState) => ({ ...prevState, isError: true }));
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setValue((prevState) => ({ ...prevState, data: e.target.result }));
      });

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="input-group d-flex align-items-center gap-5 mb-4">
      <label className="required">Input Photo</label>

      <label htmlFor={id}></label>

      <div className="d-flex flex-column">
        <Input
          required
          className={clsx(val.isError && "border border-2 border-danger")}
          id={id}
          type="file"
          accept="image/jpeg, image/jpg"
          onChange={handleChange}
        />
        <small>{description}</small>
        {val.isError && <small className="text-danger mt-2">Image size should be less than 1MB.</small>}
      </div>
    </div>
  );
};
