import useForm from "../hooks/useForm";
import { Input } from "./Input";
import * as atom from "../stores";
import clsx from "clsx";

const GenderRadio = ({ column = false, name, male, female, atomVal = atom.genderAtom }) => {
  const [, changeGender] = useForm(atomVal);

  return (
    <div
      className={clsx("input-group d-flex mb-4", column ? "flex-column gap-2" : "align-items-center gap-5")}>
      <label role="button" className="required" htmlFor="gender">
        Gender
      </label>
      {column ? (
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-center gap-2">
            <Input
              required
              role="button"
              className="form-check-input m-0 p-0"
              type="radio"
              name={name ?? "gender"}
              id={male ?? "male"}
              value="male"
              onChange={changeGender}
            />
            <label role="button" className="form-check-label" htmlFor={male ?? "male"}>
              Male
            </label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Input
              required
              role="button"
              className="form-check-input m-0 p-0"
              type="radio"
              name={name ?? "gender"}
              id={female ?? "female"}
              value="female"
              onChange={changeGender}
            />
            <label role="button" className="form-check-label" htmlFor={female ?? "female"}>
              Female
            </label>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="d-flex align-items-center gap-2">
            <Input
              required
              role="button"
              className="form-check-input m-0 p-0"
              type="radio"
              name="gender"
              id="male"
              value="male"
              onChange={changeGender}
            />
            <label role="button" className="form-check-label" htmlFor="male">
              Male
            </label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Input
              required
              role="button"
              className="form-check-input m-0 p-0"
              type="radio"
              name="gender"
              id="female"
              value="female"
              onChange={changeGender}
            />
            <label role="button" className="form-check-label" htmlFor="female">
              Female
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default GenderRadio;
