import useForm from "../hooks/useForm";

const Select = ({ label, id, data, atom }) => {
  const [state, changeState] = useForm(atom);

  return (
    <div className="input-group d-flex align-items-center gap-5 mb-4">
      <label className="required" htmlFor={id}>
        {label}
      </label>
      <select
        required
        className="form-select max-w-24"
        name={id}
        id={id}
        value={state.data}
        onChange={changeState}>
        {data.length > 0 && data.map((prop, id) => <option key={id}>{prop}</option>)}
      </select>
    </div>
  );
};

export default Select;
