import React from 'react'


function Header(){
    return(
        <header className="p-3 mb-3 border-bottom">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-center">
            <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
              <img src='/logo-1.jpg' style={{width:'200px'}} alt="Jelajahin"/>
            </a>
          </div>
        </div>
      </header>
    )
}


export default Header