import useForm from "../hooks/useForm";
import { jerseySize } from "../libs/constant";
import { Input } from "./Input";
import * as atom from "../stores";

const JerseySizeRadio = () => {
  const [, changeSize] = useForm(atom.jerseySizeAtom);

  return (
    <div className="input-group d-flex align-items-center gap-5 mb-4">
      <div className="d-flex flex-direction-column">
        <label className="required">Jersey Size</label>
        <img
          src="/jersey.png"
          alt="jersey"
          loading="lazy"
          width={120}
          height={120}
          style={{ objectFit: "contain" }}
        />
      </div>

      <div className="d-flex flex-column gap-4">
        {jerseySize.map((item, idx) => (
          <div key={idx} className="d-flex align-items-center gap-2">
            <Input
              required
              className="form-check-input m-0 p-0"
              type="radio"
              name="jersey-size"
              id={item}
              value={item}
              onChange={changeSize}
            />
            <label className="form-check-label text-sm" htmlFor={item}>
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JerseySizeRadio;
