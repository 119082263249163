import { atom } from "jotai";
const initialState = [
  {
    category: "MARATHON",
    price: 2_000_000,
    qty: 0,
  },
  {
    category: "HALF MARATHON",
    price: 1_600_000,
    qty: 0,
  },
  {
    category: "10K",
    price: 1_100_000,
    qty: 0,
  },
  {
    category: "5K",
    price: 7_00_000,
    qty: 0,
  },
];

const inputInitialState = {
  isError: false,
  data: "",
};

const treatInitalState = {
  isError: false,
  answer: "",
  description: "",
};

const vaccinationInitialState = {
  data: null,
  isError: false,
};

export const categoryTicket = atom(initialState);
export const selectedTicket = atom({
  category: null,
  qty: 0,
  total: 0,
});

export const firstNameAtom = atom(inputInitialState);
export const lastNameAtom = atom(inputInitialState);
export const emailAtom = atom(inputInitialState);
export const genderAtom = atom(inputInitialState);
export const bloodTypeAtom = atom(inputInitialState);
export const nationalityAtom = atom(inputInitialState);
export const phoneAtom = atom(inputInitialState);
export const emergencyPhoneAtom = atom(inputInitialState);
export const addressAtom = atom(inputInitialState);
export const cityAtom = atom(inputInitialState);
export const stateAtom = atom(inputInitialState);
export const countryAtom = atom(inputInitialState);
export const jerseySizeAtom = atom(inputInitialState);
export const alergiesInfo = atom(treatInitalState);
export const medicalInfo = atom(treatInitalState);

export const vaccination = atom(vaccinationInitialState);
export const vaccinationBoster = atom(vaccinationInitialState);

// personal information
// export const firstNamePersonal = atom(inputInitialState);
// export const lastNamePersonal = atom(inputInitialState);
// export const emailPersonal = atom(inputInitialState);
// export const phonePersonal = atom(inputInitialState);
export const IDNumber = atom(inputInitialState);
// export const genderPersonal = atom(inputInitialState);
// export const dateOfBirthPersonal = atom({
//   day: "",
//   month: "",
//   year: "",
// });
// export const agreePersonal = atom({ ...inputInitialState, data: "yes" });

// // payment info
// export const selectedPayment = atom({
//   isError: false,
//   data: "",
// });

export const isAgreeTerms = atom(false);
export const refNumber = atom("");
