import { useAtom } from "jotai";
import { useState } from "react";
import * as atom from "../stores";

import "react-datepicker/dist/react-datepicker.css";
import { ModalCentered } from "../components/ModalCentered";
import RegisterForm from "../components/RegisterForm";
import { getTransactionDetailsByReferenceNumber } from "../libs/validateTicket";

function FormPages() {
  const [bookingCode, setBookingCode] = useState("");
  const [, setRefnum] = useAtom(atom.refNumber);
  const [isLoading, setIsLoading] = useState(false);
  const [isErr, setIsErr] = useState({
    isFieldEmpty: false,
    isHttpError: false,
    isTokenEmpty: false,
    isNotPaid: false,
  });

  // determine wther form is visible to end user or not
  const [visible, setVisible] = useState(false);

  // batch update state
  const dispatch = (err, load, visible) => {
    setIsErr((prev) => ({ ...prev, ...err }));
    setIsLoading(load);
    setVisible(visible);
    setBookingCode("");
  };

  // handle click, validate ticket booking
  const handleClick = async () => {
    // show modal error when tickets empty
    if (bookingCode.length === 0) {
      dispatch({ isFieldEmpty: true }, false, false);
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");

    // shows error if there are no token exist on localStorage
    if (!token) {
      dispatch({ isTokenEmpty: true }, false, false);
      return;
    }

    try {
      // get transaction details by reference number, passing booking code and token
      // returns isError and the data itself
      const response = await getTransactionDetailsByReferenceNumber(bookingCode, token);

      setRefnum(bookingCode);

      if (response.isError) {
        dispatch({ isHttpError: true }, false, false);
        setRefnum("");
        return;
      }

      if (response.data.data.paymentStatus.name !== "PAID") {
        dispatch({ isNotPaid: true }, false, false);
        setRefnum("");
        return;
      }

      dispatch({ isHttpError: false }, false, true);
      return;
    } catch (error) {
      dispatch({ isHttpError: true }, false, false);
      console.info(error);
      return;
    }
  };

  return (
    <div className="container">
      <ModalCentered
        title="Invalid Booking Ticket"
        body="Failed to validate booking ticket, please try again"
        show={isErr.isHttpError}
        onHide={() => setIsErr((prev) => ({ ...prev, isHttpError: false }))}
      />

      <ModalCentered
        title="Unpaid Ticket!"
        body="OOps, it looks like the booking ticket you send has not been paid yet, please complete payment and comeback later."
        show={isErr.isNotPaid}
        onHide={() => setIsErr((prev) => ({ ...prev, isNotPaid: false }))}
      />

      <ModalCentered
        title="Input Your Booking Ticket"
        body="Oops, it looks like you are sending an empty booking code, please try again"
        show={isErr.isFieldEmpty}
        onHide={() => setIsErr((prev) => ({ ...prev, isFieldEmpty: false }))}
      />

      <ModalCentered
        title="Something Went Wrong"
        body="Something went wrong, users' token not found, please try again in a minute"
        show={isErr.isTokenEmpty}
        onHide={() => setIsErr((prev) => ({ ...prev, isTokenEmpty: false }))}
      />

      {!visible && (
        <>
          <div className="d-flex justify-content-center title-form">Active Your Booking Ticket</div>
          <div className="container-form mt-4">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <div className="container-form-booking d-flex flex-row ">
                  <div className="d-flex flex-fill">
                    <input
                      value={bookingCode}
                      onChange={(e) => setBookingCode(e.target.value)}
                      type="text"
                      className="form-booking"
                      placeholder="Input Your Booking Code"
                      aria-label="Search"
                    />
                  </div>
                  <div className="d-flex flex-fill justify-content-end p-2 al">
                    <button
                      type="button"
                      className="btn-active btn"
                      onClick={handleClick}
                      disabled={bookingCode.length === 0 || isLoading}>
                      {isLoading ? (
                        <div className="spinner-border text-light spinner-border-sm" role="status"></div>
                      ) : (
                        <span>Register</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {visible && <RegisterForm />}
    </div>
  );
}

export default FormPages;
