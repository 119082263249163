import { useAtom } from "jotai";

/**
 *
 * @returns {[any, (newValue: any) => void]}
 */
const useForm = (atom) => {
  const [state, setState] = useAtom(atom);

  const changeAtom = (e) => setState((prevState) => ({ ...prevState, data: e.target.value }));

  return [state, changeAtom];
};

export default useForm;
