import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDgG6skAaH5WR4vozmzyYQ0FQQFTe5pf78",
  authDomain: "globaltix-e8558.firebaseapp.com",
  projectId: "globaltix-e8558",
  storageBucket: "globaltix-e8558.appspot.com",
  messagingSenderId: "410407124688",
  appId: "1:410407124688:web:f48d0cd20e98c31b7a28d5",
  measurementId: "G-BDY9D56Z9R",
};

const database = firebase.initializeApp(firebaseConfig);

export default database;
