import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePayload from "../../hooks/usePayload";
import { postTicket } from "../../libs/postTicket";
import { ModalCentered } from "../ModalCentered";
import { Spinner } from "../Spinner";
// import PersonalSection from "./PersonalSection";
import TicketSection from "./TicketSection";
import * as atom from "../../stores";
import clsx from "clsx";

const RegisterForm = () => {
  const payload = usePayload();

  const [isLoading, setIsLoading] = useState(false);

  const [err, setErr] = useState({
    isNotAgree: false,
    isHttpError: false,
  });

  /**
   *
   * @param {{isNotAgree: boolean; isHttperror: boolean}} newState
   * @returns
   */
  const updateError = (newState) => setErr((prev) => ({ ...prev, ...newState }));

  const [isAgree, setIsAgree] = useAtom(atom.isAgreeTerms);

  const [isSuccess, setIsSuccess] = useState(false);
  const navigateTo = useNavigate();
  console.log(payload);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const html = document.documentElement;

    if (!isAgree) {
      updateError({ isNotAgree: true });
      return;
    }

    setIsLoading(true);
    html.style.overflowY = "hidden";

    try {
      const res = await postTicket(payload);

      if (res.status !== 200) {
        updateError({ isHttperror: true });
        setIsLoading(false);

        html.style.overflowY = "unset";
        return;
      }

      setIsLoading(false);
      setIsSuccess(true);
      navigateTo("/ticket-success", {
        replace: true,
      });

      html.style.overflowY = "unset";
      return;
    } catch (error) {
      updateError({ isHttperror: true });
      setIsLoading(false);
      html.style.overflowY = "unset";
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsSuccess(false), 1250);

    return () => clearTimeout(timer);
  }, [isSuccess]);

  return (
    <div className="container-md shadow-sm py-5">
      {isLoading && <Spinner />}

      {err.isHttpError && (
        <ModalCentered
          show={err.isHttpError}
          onHide={() => updateError({ isHttperror: false })}
          title="Failed to post customers"
          body="Failed to post data to the server, please try again.."
        />
      )}

      {err.isNotAgree && (
        <ModalCentered
          show={err.isNotAgree}
          onHide={() => updateError({ isNotAgree: false })}
          title="Please Agree To Our TOC"
          body="You need to agree to our terms and condition"
        />
      )}

      {isSuccess && (
        <ModalCentered
          show={isSuccess}
          onHide={() => setIsSuccess(false)}
          title="Success"
          body="customers created!"
          noButton
        />
      )}

      <form onSubmit={handleSubmit}>
        <TicketSection />
        {/* <PersonalSection /> */}
        <div className="form-check my-3">
          <input
            checked={isAgree}
            onChange={() => setIsAgree((prev) => !prev)}
            type="checkbox"
            className={clsx("form-check-input cursor-pointer", err.isNotAgree && "border-danger")}
            id="agree-vaccination"
          />
          <label className="form-check-label required cursor-pointer" htmlFor="agree-vaccination">
            <p className={clsx(err.isNotAgree && "text-danger")}>
              I confirm if the event organizer found data discrepancy with PeduliLindungi, then the event
              organizer will cancel and refund the ticket
            </p>
            <p className={clsx(err.isNotAgree && "text-danger")}>
              Dengan ini saya menyetujui jika panitia menemukan ketidakcocokan dengan data pedulilindungi,
              maka panitia berhak untuk membatalkan dan mengembalikan seluruh biaya ticket
            </p>
          </label>
        </div>
        <button className="btn btn-primary w-100">Register</button>
      </form>
    </div>
  );
};

export default RegisterForm;
