import { useAtom } from "jotai";
import { Input } from "./Input";

const TreatmentInfo = ({ label, placeholder, atom }) => {
  const [state, changeState] = useAtom(atom);

  return (
    <>
      <div className="input-group d-flex align-items-center gap-5 mb-4">
        <label className="required">{label}</label>

        <select
          className="form-select max-w-24"
          value={state.answer}
          onChange={(e) => changeState((prevState) => ({ ...prevState, answer: e.target.value }))}>
          <option value="-">-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

      <div className="input-group d-flex align-items-center gap-5 mb-4">
        <label>If so, please explain</label>

        <Input
          type="text"
          placeholder={placeholder}
          value={state.description}
          onChange={(e) => changeState((prevState) => ({ ...prevState, description: e.target.value }))}
        />
      </div>
    </>
  );
};

export default TreatmentInfo;
