import { Spinner as BSpinner } from "react-bootstrap";

export const Spinner = () => {
  return (
    <div className="loading-backdrop">
      <BSpinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </BSpinner>
    </div>
  );
};
