import * as atom from "../../stores";

import InputGroup, { InputGroupDouble, InputGroupTriple } from "../InputGroup";
import { countries, bloodType as bt } from "../../libs/constant";
import GenderRadio from "../GenderRadio";
import Select from "../Select";
import JerseySizeRadio from "../JerseySizeRadio";
import TreatmentInfo from "../TreatmentInfo";
import { InputPhoto } from "../InputPhoto";
import { useAtom } from "jotai";
import { Input } from "../Input";

const TicketSection = () => {
  const [refNum] = useAtom(atom.refNumber);

  return (
    <section>
      <h3>Booking Information</h3>

      <div className="w-full border p-4">
        <div className="input-group d-flex align-items-center gap-5 mb-4">
          <label>Reference Number</label>
          <Input type="text" value={refNum} readOnly />
        </div>

        <InputGroupDouble />

        {/* <div className="input-group d-flex flex-column gap-2 mb-4">
          <label className="required">
            No. ID Card (NIK, KITAS, Passport No.){" "}
            <a href="https://www.loket.com/blog/how-to-purchase-ticket">Instruction for Foreigner</a>
          </label>
          <Input
            className="w-100"
            type="number"
            aria-label="ID Card"
            placeholder="Please Provde an No. ID Card"
          />
        </div> */}

        <InputGroup
          label={
            <>
              <span>No. ID Card (NIK, KITAS, Passport No.)</span>
              <a className="d-block" href="https://www.loket.com/blog/how-to-purchase-ticket">
                Instruction for Foreigner
              </a>
            </>
          }
          atom={atom.IDNumber}
          placeholder="Please provide your ID Card Number"
          type="number"
        />

        <InputGroup atom={atom.emailAtom} label="Email" placeholder="Email Address" type="email" />

        <GenderRadio />

        <Select data={bt} atom={atom.bloodTypeAtom} id="blood-type" label="Blood Type" />

        <Select data={["-", ...countries]} atom={atom.nationalityAtom} id="nationality" label="Nationality" />

        <InputGroup label="Telephone" placeholder="Phone Number" type="number" atom={atom.phoneAtom} />

        <InputGroup
          label="Emergency Telephone"
          placeholder="Emergency Phone Number"
          type="number"
          atom={atom.emergencyPhoneAtom}
        />

        <InputGroup label="Address" placeholder="Your Address, eg: 24 Manhattan" atom={atom.addressAtom} />

        <InputGroupTriple />

        <JerseySizeRadio />

        <TreatmentInfo
          label="Do you have allergies that we should know about?"
          placeholder="please explain if you have allergies"
          atom={atom.alergiesInfo}
        />

        <TreatmentInfo
          label="Do you have a specific medical conditions?"
          placeholder="please explain if you have medical treatment"
          atom={atom.medicalInfo}
        />

        <InputPhoto
          atom={atom.vaccination}
          description="Upload your vaccination proof (Max. Size : 1 MB, .jpg format)"
          id="vaccination proof"
        />

        <InputPhoto
          atom={atom.vaccinationBoster}
          description="Upload your Booster vaccination proof (Max. Size : 1 MB, .jpg format)"
          id="booster vaccination proof"
        />
      </div>
      {/* <p className="d-block mx-2 mt-2">
        Dengan menekan Tombol Pesan Sekarang di bawah ini, Saya telah membaca & setuju dengan TOC atau aturan
        yang telah disebutkan.
      </p>

      <button onClick={handleChange} className="btn btn-primary d-block w-full">
        Order Now
      </button> */}
    </section>
  );
};

export default TicketSection;
