import clsx from "clsx";

/**
 *
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} param0
 * @returns
 */
export const Input = ({ className, ...props }) => {
  return <input className={clsx("form-control", className)} type={props.type ?? "text"} {...props} />;
};
