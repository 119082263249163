import { Modal, Button } from "react-bootstrap";

export const ModalCentered = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={props.noButton ? "text-success" : "text-damger"}>
          {props.title ?? "No title provided"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.body ?? "no message provided"}</p>
      </Modal.Body>
      {!props.noButton && (
        <Modal.Footer>
          <Button onClick={props.onHide} variant="secondary">
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
