import Axios from "axios";

const ticket = Axios.create({
  baseURL: process.env.REACT_APP_MARATHON_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

export const postTicket = async (payload) => {
  try {
    const res = await ticket.post("/api/customers", payload, {
      headers: {
        Authorization: "7g88df9kre8hkd06gkb",
      },
    });
    return res;
  } catch (err) {
    console.warn("error, can't perform network request, reason - ", err);
  }
};
