import Axios from "axios";

const validateTicket = Axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

/**
 *
 * @param {string} refNum the reference number of the ticket
 * @param {string} partnerRefNum the partner reference number of the ticket
 * @param {string} token the token of the user logged in
 */
export const getTransactionDetailsByReferenceNumber = async (refNum, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await validateTicket.get(
      `/api/transaction/getTransactionDetailsByReferenceNumber?reference_number=${refNum}`,
      config,
    );

    if (response.status !== 200) {
      return {
        isError: true,
        data: null,
      };
    }

    if (response.data.success === false) {
      return {
        isError: true,
        data: response.data,
      };
    }

    return {
      isError: false,
      data: response.data,
    };
  } catch (err) {
    console.log(err);
    return {
      isError: true,
      data: null,
    };
  }
};
