import { useAtom } from "jotai";
import * as atom from "../stores";

const usePayload = () => {
  const [firstName] = useAtom(atom.firstNameAtom),
    [lastName] = useAtom(atom.lastNameAtom),
    [email] = useAtom(atom.emailAtom),
    [blood] = useAtom(atom.bloodTypeAtom),
    [nationality] = useAtom(atom.nationalityAtom),
    [phone] = useAtom(atom.phoneAtom),
    [address] = useAtom(atom.addressAtom),
    [country] = useAtom(atom.countryAtom),
    [state] = useAtom(atom.stateAtom),
    [city] = useAtom(atom.cityAtom),
    [jerseysz] = useAtom(atom.jerseySizeAtom),
    [alergies] = useAtom(atom.alergiesInfo),
    [medical] = useAtom(atom.medicalInfo),
    [vaccination] = useAtom(atom.vaccination),
    [vaccinationBooster] = useAtom(atom.vaccinationBoster),
    [IDNumber] = useAtom(atom.IDNumber),
    data = {
      identity_id: IDNumber.data,
      first_name: firstName.data,
      last_name: lastName.data,
      email: email.data,
      telephone: phone.data,
      blood_type: blood.data,
      nationality: nationality.data,
      address: {
        full_address: address.data,
        country: country.data,
        state: state.data,
        city: city.data,
      },
      jersey_size: jerseysz.data,
      allergies: {
        is_exist: alergies.answer === "Yes" ? true : false,
        desc: alergies.description,
      },
      medical_history: {
        is_exist: medical.answer === "Yes" ? true : false,
        desc: medical.description,
      },
      vaccination_proof_img: vaccination.data,
      vaccination_booster_proof_img: vaccinationBooster.data,
    };

  return data;
};

export default usePayload;
